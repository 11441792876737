import { useLazyQuery } from '@apollo/client'
import {
  ListPostsByChannelQueryVariables,
  ModelSortDirection,
  OnPostEventSubscription,
  VideoStatus,
} from '../../../../../../../../API'
import gql from 'graphql-tag'
import { AuthContext } from '../../../../../../../../common/providers/AuthStatusProvider'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { listPostsByChannel } from '../../../../../../../../common/api/graphql/queries'
import CognitoApolloClient from '../../../../../../../../common/clients/CognitoApolloClient'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../common/redux/store/store'
import useOnPostSeenSubscription from '../../../../../../../../common/layouts/AppLayout/common/hooks/subscriptions/useOnPostSeenSubscription'
import { OnPostsSeenSubscription } from '../../../../../../../../common/layouts/AppLayout/common/hooks/subscriptions/onPostSeen.subscription'

/*
 * This hook is used to subscribe to check unread status for posts received from partner
 */
const usePostSeenStatus = () => {
  const auth = useContext(AuthContext)
  const { id: channelParamsId } = useParams() as { id: string }
  const channelId = channelParamsId || auth.user?.supportChannelId
  const onPostEventSubscriptionData = useSelector((state: RootState) => state.chat.onPostEventSubscriptionData)
  const { data: postSeenSubData } = useOnPostSeenSubscription()
  const [pageVisible, setPageVisible] = useState(true)

  /*
   * This function is used to refetch posts when new post subscription is triggered
   * This is needed because querying for listPostsByChannel triggers onPostsSeen subscription
   * So we need to refetch posts to get updated unread status on every new post delivered from partner
   */
  const [refetchPost] = useLazyQuery<never, ListPostsByChannelQueryVariables>(gql(listPostsByChannel))

  useEffect(() => {
    const listenerFunc = () => {
      if (document.visibilityState === 'visible') {
        setPageVisible(true)
      } else {
        setPageVisible(false)
      }
    }
    document.addEventListener('visibilitychange', listenerFunc)
    return () => {
      document.removeEventListener('visibilitychange', listenerFunc)
    }
  }, [])

  const handleChangeSeenStatus = async (data?: OnPostEventSubscription) => {
    const _data = data?.onPostEvent
    if (_data?.post?.customerId === auth.user?.id || _data?.channelId !== channelId || !pageVisible) return
    if (
      (_data?.post?.postType === 'video' &&
        _data?.post?.video?.status === VideoStatus.ok &&
        _data?.post?.video?.name?.includes('.webm')) ||
      (_data?.post?.postType === 'video' && _data?.post?.video?.status !== VideoStatus.ok)
    )
      return
    if (
      (_data?.action === 'create' && _data?.post?.postType !== 'file') ||
      (_data?.action === 'update' && (_data?.post?.postType === 'file' || _data?.post?.postType === 'video'))
    ) {
      await refetchPost({
        variables: {
          channelId: channelId || '',
          limit: 5,
          sortDirection: ModelSortDirection.DESC,
        },
        client: CognitoApolloClient,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
      })
    }
  }

  /*
   * Subscription invoked when new post is received from partner
   * When subscription is triggered, we refetch the newest post
   * It causes unread status update in database
   */
  useEffect(() => {
    if (!onPostEventSubscriptionData) return
    const update = async () => {
      handleChangeSeenStatus(onPostEventSubscriptionData)
    }
    update()
  }, [onPostEventSubscriptionData])

  const updateSeenStatus = (options?: OnPostsSeenSubscription | undefined | null) => {
    const _data = options?.onPostsSeen
    if (_data?.customerId === auth.user?.id || !_data?.postIds?.length) return
    _data.postIds.forEach((postId) => {
      CognitoApolloClient.cache.writeFragment({
        id: `screensightPost:${postId}`,
        fragment: gql`
          fragment postEngagement on screensightPost {
            engagement {
              items {
                customerId
                seen
                seenAt
                videoProgress
              }
              nextToken
            }
          }
        `,
        data: {
          engagement: {
            items: [
              {
                customerId: _data?.customerId,
                seen: true,
                seenAt: _data?.timestamp,
                videoProgress: null,
              },
            ],
            nextToken: null,
          },
        },
      })
    })
  }

  useEffect(() => {
    if (!postSeenSubData) return
    updateSeenStatus(postSeenSubData?.data)
  }, [postSeenSubData])
}

export default usePostSeenStatus
