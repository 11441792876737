import { useQuery } from '@apollo/client'
import {
  ListCustomerRecentChannelsQueryVariables,
  ModelSortDirection,
  OnPostEventSubscription,
  screensightChannel,
} from '../../../../../../../API'
import gql from 'graphql-tag'
import CognitoApolloClient from '../../../../../../../common/clients/CognitoApolloClient'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../../../common/providers/AuthStatusProvider'
import ToastContext from '../../../../../../../common/providers/ToastProvider/ToastContext'
import { useHistory } from 'react-router-dom'
import { useLocation, useParams } from 'react-router'
import moment from 'moment'
import {
  listCustomerRecentChannels,
  ListCustomerRecentChannelsQuery,
} from '../../../../../../../common/api/graphql/listCustomerRecentChannels'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../common/redux/store/store'
import { useNetworkState } from 'react-use'

const useChannelSidebar = () => {
  const auth = useContext(AuthContext)
  const toastContext = useContext(ToastContext)
  const location = useLocation()
  const history = useHistory()
  const networkState = useNetworkState()
  const onPostEventSubscriptionData = useSelector((state: RootState) => state.chat.onPostEventSubscriptionData)

  const [unreadChannels, setUnreadChannels] = useState<string[]>([])
  const [directChannelsWithoutRecent, setDirectChannelsWithoutRecent] = useState<screensightChannel[]>([])
  const [recentChannels, setRecentChannels] = useState<screensightChannel[]>([])
  const { id: channelId } = useParams() as { id: string }
  const { t } = useTranslation('chat')

  const {
    data: listChannelsByCustomerData,
    error: listChannelsByCustomerError,
    loading: listChannelsByCustomerLoading,
    fetchMore: fetchMoreChannelsData,
    startPolling,
    stopPolling,
  } = useQuery<ListCustomerRecentChannelsQuery, ListCustomerRecentChannelsQueryVariables>(
    gql(listCustomerRecentChannels),
    {
      variables: {
        customerId: auth.user?.id || '',
        sortDirection: ModelSortDirection.DESC,
        limit: 25,
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !auth.isInitialized || !auth.isAuthenticated,
      client: CognitoApolloClient,
    },
  )

  useEffect(() => {
    const refetch = async () => {
      if (auth.user?.pending || !channelId || !networkState?.online) return
      if (document.visibilityState === 'visible') {
        startPolling(1000)
        setTimeout(() => stopPolling(), 1500)
        setTimeout(() => startPolling(15000), 2000)
      } else {
        stopPolling()
      }
    }
    document.addEventListener('visibilitychange', refetch)
    return () => document.removeEventListener('visibilitychange', refetch)
  }, [channelId, auth.user?.pending, networkState?.online])

  useEffect(() => {
    if (!networkState?.online) {
      stopPolling()
      return
    }
    startPolling(1000)
    setTimeout(() => stopPolling(), 1500)
    setTimeout(() => startPolling(15000), 2000)
  }, [networkState?.online])

  const loadNextChannels = async () => {
    if (!listChannelsByCustomerData?.listCustomerRecentChannels?.nextToken) return
    await fetchMoreChannelsData({
      variables: {
        limit: 20,
        nextToken: listChannelsByCustomerData?.listCustomerRecentChannels?.nextToken,
        channelId: channelId,
      },
    })
  }

  const updateChannelFragment = (data?: OnPostEventSubscription) => {
    if (
      !data?.onPostEvent?.channelId ||
      data.onPostEvent.action === 'update' ||
      data.onPostEvent.action === 'delete' ||
      !auth.user?.id ||
      data?.onPostEvent?.post?.customerId === auth.user?.id
    )
      return
    CognitoApolloClient.cache.writeFragment({
      id: `screensightChannel:${data.onPostEvent.channelId}`,
      fragment: gql`
        fragment channelFragment on screensightChannel {
          activity {
            new
            latestAt
            latestPost {
              customerId
            }
          }
        }
      `,
      data: {
        activity: {
          new: true,
          latestAt: data.onPostEvent?.post?.createdAt,
          latestPost: {
            customerId: data.onPostEvent?.post?.customerId,
          },
        },
      },
    })
  }

  useEffect(() => {
    const updateChannels = async () => {
      if (!onPostEventSubscriptionData) return
      updateChannelFragment(onPostEventSubscriptionData)
    }
    updateChannels()
  }, [onPostEventSubscriptionData])

  useEffect(() => {
    if (!channelId) return
    CognitoApolloClient.cache.modify({
      id: `screensightChannel:${channelId}`,
      broadcast: true,
      fields: {
        activity(existingActivity = {}) {
          const newActivity = {
            new: false,
          }
          return { ...existingActivity, ...newActivity }
        },
      },
    })
  }, [channelId])

  useEffect(() => {
    if (!listChannelsByCustomerData?.listCustomerRecentChannels?.items?.length) return
    const newChannels = ((listChannelsByCustomerData?.listCustomerRecentChannels?.items as screensightChannel[]) || [])
      .filter((channel) => channel.activity?.new && channel.activity.latestPost?.customerId !== auth.user?.id)
      .map((channel) => channel.id)
    // @ts-ignore
    setUnreadChannels([...new Set([...newChannels])])
  }, [listChannelsByCustomerData, auth.user?.id])

  useEffect(() => {
    if (!unreadChannels?.length || !location.pathname.includes('chat')) return
    const list = [...unreadChannels]
    setUnreadChannels(list.filter((channelId) => channelId !== location.pathname.substring(10)))
  }, [location.pathname, unreadChannels?.length])

  useEffect(() => {
    if (listChannelsByCustomerError) {
      toastContext.open({ type: 'ERROR', text: t('chatBody.channelsLoadingError') })
    }
  }, [listChannelsByCustomerError])

  useEffect(() => {
    if (location.pathname !== '/app/chat') return
    const lastChannel = localStorage.getItem('lastChannel')
    if (lastChannel === 'new' || !lastChannel) history.push('/app/chat/new')
    if (listChannelsByCustomerLoading) return
    if (!listChannelsByCustomerLoading && listChannelsByCustomerData?.listCustomerRecentChannels?.items?.length) {
      const channelExist = listChannelsByCustomerData?.listCustomerRecentChannels?.items?.find(
        // @ts-ignore
        (item: screensightChannel) => item?.id === lastChannel,
      )
      if (channelExist && lastChannel) {
        history.replace(`/app/chat/${lastChannel}`)
      } else {
        history.push('/app/chat/new')
      }
    } else {
      history.push('/app/chat/new')
    }
  }, [location.pathname, listChannelsByCustomerData?.listCustomerRecentChannels?.items, listChannelsByCustomerLoading])

  useEffect(() => {
    if (!channelId || !location.pathname.includes('chat')) return
    localStorage.setItem('lastChannel', channelId)
  }, [channelId])

  const sortChannels = (x: any, y: any) => {
    if (x.activity.latestAt === null) return 1
    if (y.activity.latestAt === null) return -1
    if (y.activity.latestAt === x.activity.latestAt) return 0
    return x.activity.latestAt < y.activity.latestAt ? 1 : -1
  }

  useEffect(() => {
    if (!listChannelsByCustomerData?.listCustomerRecentChannels?.items?.length) return
    const date = new Date()
    date.setDate(date.getDate() - (auth?.user?.settings?.channelRecentActivityDays || 3))

    const allDirectChannels =
      listChannelsByCustomerData?.listCustomerRecentChannels?.items
        .filter((channel) => channel?.channelType === 'direct_message' || channel?.channelType === 'guest')
        ?.sort(sortChannels) || []

    const _recentDirectChannels: screensightChannel[] = (allDirectChannels?.filter((channel) => {
      return moment(channel?.activity?.latestAt).isAfter(date)
    }) || []) as screensightChannel[]

    const recentDirectChannelsIds = _recentDirectChannels.map((recentChannel) => recentChannel?.id)

    const _directChannelsWithoutRecent: screensightChannel[] = allDirectChannels.filter(
      (channel) => !recentDirectChannelsIds.includes(channel?.id || ''),
    ) as screensightChannel[]

    setDirectChannelsWithoutRecent(_directChannelsWithoutRecent)
    setRecentChannels(_recentDirectChannels)
  }, [listChannelsByCustomerData?.listCustomerRecentChannels?.items, auth?.user?.settings?.channelRecentActivityDays])

  return {
    unreadChannels,
    recentChannels: recentChannels,
    directChannels: directChannelsWithoutRecent,
    loadNextChannels,
    hasMore: !!listChannelsByCustomerData?.listCustomerRecentChannels?.nextToken,
    dataLength: listChannelsByCustomerData?.listCustomerRecentChannels?.items?.length,
    loading: listChannelsByCustomerLoading,
  }
}

export default useChannelSidebar
