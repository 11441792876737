/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "e8e30d646e61465da93c160877bb82f8",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://737xxf5omrfxjnizrzdlcm5awu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:95760097-60ad-477e-ad0c-8d133ddf340a",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_tN7oCRZ7S",
    "aws_user_pools_web_client_id": "54f215i2n5d4koqb28pb3lmre2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "screensightApplicationConfiguration-qa",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "screensight-video-22aa7650145055-qa",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
