import { useQuery } from '@apollo/client'
import {
  listCustomerRecentChannels,
  ListCustomerRecentChannelsQuery,
} from '../../../../api/graphql/listCustomerRecentChannels'
import {
  ListCustomerRecentChannelsQueryVariables,
  ModelSortDirection,
  OnPostEventSubscription,
  screensightChannel,
} from '../../../../../API'
import gql from 'graphql-tag'
import CognitoApolloClient from '../../../../clients/CognitoApolloClient'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../providers/AuthStatusProvider'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setUnreadChannelsCount } from '../../../../../pages/Chat/common/redux/store/chat-actions'
import { RootState } from '../../../../../pages/Chat/common/redux/store/store'

/*
  hook to get all unread channels including support channel
 */
const useUnreadChannelsNotifications = () => {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const dispatch = useDispatch()
  const onPostEventSubscriptionData = useSelector((state: RootState) => state.chat.onPostEventSubscriptionData)

  /*
   array of ids of all unread channels including support channel
   */
  const [unreadChannelsIds, setUnreadChannelsIds] = useState<string[]>([])
  const [isSupportChannelUnread, setIsSupportChannelUnread] = useState(false)
  const [pageVisible, setPageVisible] = useState(true)

  useEffect(() => {
    const listenerFunc = () => {
      if (document.visibilityState === 'visible') {
        setPageVisible(true)
      } else {
        setPageVisible(false)
      }
    }
    document.addEventListener('visibilitychange', listenerFunc)
    return () => {
      document.removeEventListener('visibilitychange', listenerFunc)
    }
  }, [])

  const { data } = useQuery<ListCustomerRecentChannelsQuery, ListCustomerRecentChannelsQueryVariables>(
    gql(listCustomerRecentChannels),
    {
      variables: {
        customerId: auth.user?.id || '',
        limit: 11,
        sortDirection: ModelSortDirection.DESC,
      },
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      skip: auth.user?.pending || !auth.isAuthenticated,
      client: CognitoApolloClient,
    },
  )

  const handleUnreadChannelsSub = (data: OnPostEventSubscription) => {
    if (!auth.user?.id) return
    const postEvent = data?.onPostEvent
    if (
      (postEvent?.action === 'create' &&
        postEvent.post?.postType !== 'file' &&
        postEvent?.post?.customerId !== auth.user.id) ||
      (postEvent?.action === 'update' &&
        postEvent.post?.postType === 'file' &&
        postEvent?.post?.customerId !== auth.user.id)
    ) {
      if (!postEvent?.channelId) return
      setUnreadChannelsIds((prev) => {
        if (prev.includes(postEvent.channelId as string)) return prev
        return [...prev, postEvent.channelId as string]
      })
    }
  }

  useEffect(() => {
    if (!onPostEventSubscriptionData) return
    handleUnreadChannelsSub(onPostEventSubscriptionData)
  }, [onPostEventSubscriptionData])

  /*
   initial useEffect to set all unreadChannelsIds
   */
  useEffect(() => {
    if (!data?.listCustomerRecentChannels?.items || !auth.user?.id) return
    const unreadChannelsIds = ((data?.listCustomerRecentChannels?.items as screensightChannel[]) || [])
      .filter((channel) => channel.activity?.new && channel.activity.latestPost?.customerId !== auth.user?.id)
      .map((channel) => channel.id)
    setUnreadChannelsIds(unreadChannelsIds)
  }, [data?.listCustomerRecentChannels?.items, auth.user?.id])

  /*
   remove channel from unreadChannelsIds when user navigates to it
   */
  useEffect(() => {
    if (!pageVisible) return
    if (location.pathname === '/app/support') {
      setIsSupportChannelUnread(false)
      setUnreadChannelsIds((prev) => prev.filter((channel) => channel !== auth.user?.supportChannelId))
      return
    }
    if (!location.pathname.includes('/app/chat/')) return
    const channelId = location.pathname.split('/app/chat/')[1]
    setUnreadChannelsIds((prev) => {
      if (!prev.includes(channelId)) return prev
      return prev.filter((channel) => channel !== channelId)
    })
  }, [location.pathname, auth.user?.supportChannelId, unreadChannelsIds.length, pageVisible])

  /*
    set unreadChannelsCount in redux store and set isSupportChannelUnread
   */
  useEffect(() => {
    dispatch(setUnreadChannelsCount(unreadChannelsIds.length))
    if (auth.user?.supportChannelId && unreadChannelsIds.includes(auth.user?.supportChannelId)) {
      setIsSupportChannelUnread(true)
    }
  }, [unreadChannelsIds])

  return {
    isSupportChannelUnread,
    unreadChannelsCount: isSupportChannelUnread ? unreadChannelsIds.length - 1 : unreadChannelsIds.length,
  }
}

export default useUnreadChannelsNotifications
